import React from 'react';
import Navbar from '../Components/Navbar';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

function Home() {
  const navigate = useNavigate(); // Corrected the use of useNavigate

  const handleAboutClick = () => {
    navigate('/about'); // Navigates to the About page
  };
  const handleTechnicalSkillsClick = () => {
    navigate('/techskill'); // Navigates to the About page
  };

  return (
    <div className='min-h-screen bg-white'>
      
      <div className='flex justify-center items-center laptop:pt-20 pt-10'>
        <div className='rounded-full overflow-hidden w-32 h-32 border-slate-300 border-8 laptop:w-48 laptop:h-48'>
          <img src={`${process.env.PUBLIC_URL}/images/profile.webp`} className='laptop:w-38 h-auto'></img>
        </div>
        <div className='ml-2'>
          <div className='text-4xl laptop:text-5xl mb-4'>Hi! My name is Tyler</div>
          <div className='text-2xl laptop:text-3xl'>Welcome to my Portfolio!</div>
        </div>
      </div>

    <div className='laptop:flex laptop:justify-around laptop:mt-20'>
      <NavLink to='/about'>
    <div className='grid justify-items-center my-10 hover:bg-gray-800 transition mx-5 py-5 rounded-xl text-black hover:text-white'>
      <div className='rounded-full overflow-hidden w-32 h-32 flex items-center justify-center'>
        <img src={`${process.env.PUBLIC_URL}/images/aboutme.png`} className='w-16 h-auto'></img>
      </div>
      <div className='text-3xl font-bold mt-2 laptop:px-10'>About Me</div>
    </div>
    </NavLink>

    <NavLink to='/techskill'>
    <div className='grid justify-items-center my-10 hover:bg-gray-800 transition mx-5 py-5 rounded-xl text-black hover:text-white'>
      <div className='rounded-full overflow-hidden w-32 h-32 flex items-center justify-center'>
        <img src={`${process.env.PUBLIC_URL}/images/techskill.png`} className='w-16 h-auto'></img>
      </div>
      <div className='text-3xl font-bold mt-2 laptop:px-10'>My Technical Skills</div>
    </div>
    </NavLink>

    <NavLink to='/softskill'>
    <div className='grid justify-items-center my-10 hover:bg-gray-800 transition mx-5 py-5 rounded-xl text-black hover:text-white'>
      <div className='rounded-full overflow-hidden w-32 h-32 flex items-center justify-center'>
        <img src={`${process.env.PUBLIC_URL}/images/softskill.png`} className='w-16 h-auto'></img>
      </div>
      <div className='text-3xl font-bold mt-2 laptop:px-10'>My Soft Skills</div>
    </div>
    </NavLink>

    <NavLink to='/project'>
    <div className='grid justify-items-center my-10 hover:bg-gray-800 transition mx-5 py-5 rounded-xl text-black hover:text-white'>
      <div className='rounded-full overflow-hidden w-32 h-32 flex items-center justify-center'>
        <img src={`${process.env.PUBLIC_URL}/images/project.png`} className='w-16 h-auto'></img>
      </div>
      <div className='text-3xl font-bold mt-2 laptop:px-10'>My Projects</div>
    </div>
    </NavLink>
    
    </div>
    </div>
  );
}

export default Home;
