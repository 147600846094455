import React from 'react'
function Project() {
  return (
    <div className='min-h-screen visible'>
    <div className='justify-center flex mobile:my-10'>
    <div className='mobile:text-4xl laptop:text-5xl font-bold'>
    <i class="bi bi-folder-fill mr-5"></i>
        My Projects
    </div>
    </div>
    <div className="h-1.5 w-auto mobile:mx-10 my-10 bg-gray-300 rounded-full"></div>

    <div className='laptop:flex laptop:justify-around mb-10'>

    <div className='grid justify-items-center mt-5 laptop:mx-10'>
          <div className='w-32 h-32 flex items-center justify-center'>
            <img src={`${process.env.PUBLIC_URL}/images/pos.png`} className='w-16'></img>
          </div>
          <div className='text-center'>
          <div className=' mobile:text-2xl'>PointSale</div>
          <div className='flex justify-center space-x-5 mt-2'>
          <div class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 w-min text-nowrap">C++</div>
          </div>
            <button className='mt-5 h-16 border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl text-nowrap'>
            <a href='https://github.com/TylerDdao/PointSale' target='_blank'>
            <i class="bi bi-search mr-3"></i>
              For more details
              </a>
              </button>
          </div>
        </div>

        <div className='grid justify-items-center mt-5 laptop:mx-10'>
          <div className='w-32 h-32 flex items-center justify-center'>
            <img src={`${process.env.PUBLIC_URL}/images/encrypted.png`} className='w-16'></img>
          </div>
          <div className='text-center'>
          <div className=' mobile:text-2xl'>Encrypterizer</div>
          <div className='flex justify-center space-x-5 mt-2'>
          <div class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 w-min text-nowrap">C</div>
          </div>
            <button className='mt-5 h-16 border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl text-nowrap'>
            <a href='https://github.com/Nathancecixx/Encrypterizer' target='_blank'>
            <i class="bi bi-search mr-3"></i>
              For more details
              </a>
              </button>
          </div>
        </div>

        <div className='grid justify-items-center mt-5 laptop:mx-10'>
          <div className='w-32 h-32 flex items-center justify-center'>
            <img src={`${process.env.PUBLIC_URL}/images/portfolio.png`} className='w-16'></img>
          </div>
          <div className='text-center'>
          <div className=' mobile:text-2xl'>Portfolio Webiste</div>
          <div className='flex justify-center space-x-5 mt-2'>
          <div class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 w-min text-nowrap">React.js</div>
          </div>
            <button className='mt-5 h-16 border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl text-nowrap'>
            <a href='https://github.com/TylerDdao/Portfolio' target='_blank'>
            <i class="bi bi-search mr-3"></i>
              For more details
              </a>
              </button>
          </div>
        </div>

    </div>
    </div>
  )
}

export default Project