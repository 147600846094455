import React from 'react'
function Softskill() {
  return (
    <div className='min-h-screen visible'>    
    <div className='justify-center flex mobile:my-10'>
    <div className='mobile:text-4xl laptop:text-5xl font-bold'>
    <i class="bi bi-clipboard-data-fill mr-5"></i>
        My Soft Skills
    </div>
    </div>
    <div className="h-1.5 w-auto mobile:mx-10 my-10 bg-gray-300 rounded-full"></div>
    <div className='text-center mobile:text-2xl my-5 px-5'>
    </div>
    <div className='laptop:flex laptop:justify-center laptop:justify-between laptop:mx-20 mb-10'>
        <div className='grid justify-items-center mt-5 laptop:mr-5'>
          <div className='flex items-center justify-center hover:scale-110 transition'>
          <img src={`${process.env.PUBLIC_URL}/images/communication.png`} className='w-16 h-auto'></img>
          </div>
          <div className=' mobile:text-2xl laptop:text-3xl mt-5 text-center'>Communication Skill</div>
        </div>

        <div className='grid justify-items-center mt-5 laptop:mx-5'>
          <div className='flex items-center justify-center hover:scale-110 transition'>
          <img src={`${process.env.PUBLIC_URL}/images/problemsolving.png`} className='w-16 h-auto'></img>
          </div>
          <div className=' mobile:text-2xl laptop:text-3xl mt-5 text-center'>Problem Solving Skill</div>
        </div>

        <div className='grid justify-items-center mt-5 laptop:ml-5'>
          <div className='flex items-center justify-center hover:scale-110 transition'>
          <img src={`${process.env.PUBLIC_URL}/images/teamwork.png`} className='w-16 h-auto'></img>
          </div>
          <div className=' mobile:text-2xl laptop:text-3xl mt-5 text-center'>Team Working Skill</div>
        </div>
        </div>
    </div>
  )
}

export default Softskill