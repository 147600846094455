import React from 'react'
import Navbar from '../Components/Navbar'
function About() {
  return (
    <div className='min-h-screen visible'>    
    <div className='justify-center flex mobile:my-10'>
    <div className='mobile:text-4xl laptop:text-5xl font-bold'>
    <i class="bi bi-person-badge-fill mr-5"></i>
        About me
    </div>
    </div>
    <div className="h-1.5 w-auto mobile:mx-10 my-10 bg-gray-300 rounded-full"></div>
    <div className='text-center mobile:text-2xl my-10 px-5'>
    <i class="bi bi-star mr-3"></i>
    Hi, my full name is Duy Bao Nam Dao or my English name is Tyler. I am a second year computer science student.
I am currently looking for a Co-op for my Spring term in 2025. This is my Portfolio made by me using React.js, take a look! You can find all of my technical skills, soft skills and my projects in this portfolio.
    </div>
    <div className="h-1.5 w-auto my-5 bg-gray-300 mobile:mx-20 rounded-full"></div>

    <div className='laptop:flex laptop:justify-around laptop:items-end mb-10'>

        <div className='grid justify-items-center mb-10 laptop:mx-10 laptop:mt-5 laptop:mb-0 mb-10'>
          <div className='w-32 h-32 flex items-center justify-center hover:scale-110 transition'>
            <img src={`${process.env.PUBLIC_URL}/images/education.png`} className='w-16'></img>
          </div>
          <div className='text-center'>
          <div className=' mobile:text-2xl'>Conestoga College - Waterloo</div>
          <div className='mobile:text-xl text-nowrap'>Bachelor of Computer Science (Honours)</div>

          <button className='mt-5 h-16  laptop:w-auto w-full border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl text-nowrap'>
            <a href='documents/GPA.pdf' target="_blank">
            <i class="bi bi-ui-checks mr-3"></i>
              My GPA
              </a>
              </button>

          </div>
        </div>

        

        <div className='grid justify-items-center laptop:mx-10 laptop:mt-5'>
          <div className='w-32 h-32 flex items-center justify-center hover:scale-110 transition'>
          <img src={`${process.env.PUBLIC_URL}/images/email.png`} className='w-16'></img>
          </div>
          <div className='text-center'>
          <div className=' mobile:text-2xl hover:scale-110 transition group text-nowrap'><a href='mailto:baonamdao05@gmail.com'>baonamdao05@gmail.com</a><i class="bi bi-send-check ml-3 group-hover:inline-block hidden transition"></i></div>
          <div className=' mobile:text-2xl hover:scale-110 transition group text-nowrap'><a href='mailto:work@tylerddao.com'>work@tylerddao.com</a><i class="bi bi-send-check ml-3 group-hover:inline-block hidden transition"></i></div>
          
          <div className='laptop:flex laptop:space-x-5'>
            <button className='mt-5 h-16 border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl laptop:w-auto'>
            <a href='https://www.linkedin.com/in/tylerddao/' target="_blank">
            <i class="bi bi-linkedin mr-3"></i>
              My LinkedIn
              </a>
              </button>
              <button className='mt-5 h-16 border-2 border-gray-800 px-10 rounded hover:bg-gray-800 hover:text-white transition mobile:text-xl text-nowrap'>
            <a href='documents/Resume_Tyler_Dao.pdf' target="_blank">
            <i class="bi bi-file-earmark-check-fill mr-3"></i>
              My Resume
              </a>
              </button>
            </div>
          </div>
        </div>
    </div>
    </div>
  )
}

export default About